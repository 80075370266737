<template>
    <div>
        <v-app>
            <v-container fluid>
                <v-row>
                    <v-navigation-drawer app width="90" class="bgNone">
                        <div class="text-center  logoDiv">
                            <img src="../assets/Logo.svg" alt="">
                            <div>
                                <v-list class="pa-0 navigationList ml-7" flat>
                                    <div
                                        v-for="item  in routeIcons"
                                        :key="item.path"
                                        :class="[!item.active ? '' : 'NavigationIcon']"
                                        style="cursor: pointer"
                                        class="lgNavigation"
                                    >
                                        <v-list-item-icon v-if="item.active" @click="routeLinks(item)">
                                            <img :src="item.icon" alt="">
                                        </v-list-item-icon>
                                        <v-list-item-icon v-if="!item.active" @click="routeLinks(item)">
                                            <img :src="item.iconUnactive" alt="">
                                        </v-list-item-icon>

                                    </div>

                                </v-list>
                                <v-avatar class="avatar">
                                    <img src="@/assets/photo_2021-06-19_22-12-58.jpg">
                                </v-avatar>
                            </div>
                        </div>
                    </v-navigation-drawer>
                    <v-app-bar app elevation="0" height="100" class="appBar" color="white">
                        <v-col cols="12" class="mt-5 ">
                            <div class="pa-5  d-flex">
                                <div class="mt-5">
                                <img class="ma-2" src="../assets/convert-3d-cube.svg" alt="">
                                </div>
                                <div class="ml-3 mt-2">
                                    <h2>{{titleAndImages.title}}</h2>
                                    <p>{{titleAndImages.description}}</p>
                                </div>

                                <v-spacer></v-spacer>
                                <div class="d-flex mt-10">
                                <v-menu offset-y content-class="elevation-1 rounded-lg  ">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon plain v-bind="attrs" v-on="on" class=" ">
                                            <img src="@/assets/notification.svg" class="mr-2 " alt="">
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item-group  active-class="primary--text" multiple>
                                            <template v-for="item in 3">
                                                <v-list-item :key="item.title">
                                                    <template>
                                                        <v-list-item-content class=" d-block ">
                                        <span class=" black--text ">Arizachilar
                                            taftishdan o’tishi, tasdiq
                                            <br> yoki qora ro’yxatga kiritilishi kerak
                                        </span>
                                                            <p style="font-size:14px">23 daqiqa oldin</p>
                                                        </v-list-item-content>
                                                        <v-list-item-action>
                                                            <v-list-item-action-text
                                                                v-text="item.action"></v-list-item-action-text>
                                                            <v-icon v-if="!item.active" color="primary" small>
                                                                mdi-circle
                                                            </v-icon>

                                                            <v-icon v-else color="yellow" small>
                                                                mdi-circle
                                                            </v-icon>
                                                        </v-list-item-action>
                                                    </template>
                                                </v-list-item>
                                            </template>
                                        </v-list-item-group>
                                    </v-list>
                                </v-menu>

                                    <v-text-field prepend-inner-icon="mdi-magnify" outlined
                                                  style="border-radius: 14px;"
                                                  dense
                                                  class="mt-1"
                                                  label="Qidiruv..."></v-text-field>
                                    </div>
                            </div>
                        </v-col>
                    </v-app-bar>
                </v-row>
            </v-container>
            <v-main>
                <v-container class="pa-5">
                    <router-view></router-view>
                </v-container>
            </v-main>
            <v-footer app>
            </v-footer>
        </v-app>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    name: "Home.vue",
    data() {
        return {
            selectedItem: 0,
            validate:false,
            dialog1: false,
            dialog: false,
            e1: '1',
            stateSellorDebts: '',
            items: [
                {text: 'Real-Time', icon: 'mdi-clock', bg: true},
                {text: 'Audience', icon: 'mdi-account', bg: false},
                {text: 'Conversions', icon: 'mdi-flag', bg: true},
                {text: 'Real-Time', icon: 'mdi-clock', bg: false},
                {text: 'Audience', icon: 'mdi-account', bg: true},
            ],
            titleAndImage: [
                {
                    title: 'Kiritmalar',
                    path: 'Enter',
                    description: 'Sement va qop kiritmalari arxivi',
                    icon: require('../assets/convert-3d-cube.svg')
                },
                {
                    title: 'Omborxona',
                    path: 'Storage',
                    description: 'Bazada saqlanayotgan mahulotlar hisoboti',
                    icon: require('../assets/convert-3d-cube.svg')
                },
                {
                    title: 'Sotuvlar',
                    path: 'Exit',
                    description: 'Sotilgan mahsulotlar arxivi',
                    icon: require('../assets/convert-3d-cube.svg')
                },
                {
                    title: 'Qarzdorlik',
                    path: 'Debts',
                    description: 'Qarzlar va nasiyalar',
                    icon: require('../assets/convert-3d-cube.svg')
                },
                {
                    title: 'Mijozlar bazasi',
                    path: 'Clients',
                    description: 'Bizdan xarid qilgan mijozlar ma’lumoti',
                    icon: require('../assets/convert-3d-cube.svg')
                },
            ],
            disabledBtn: false,
            picker: '',
        }
    },
    computed: {
        ...mapGetters(['routeIcons']),
        titleAndImages(){
            return this.titleAndImage.find(el=>el.path===this.$route.name)
        },

    },
    methods: {
        routeLinks(item) {
            this.routeIcons.forEach(e=>{
                e.active=false
            })
            item.active=true
            this.$router.push(item.path)
        },
        sendData1() {
            this.dialog1 = false
            this.e1 = 1
        },
        sendData() {
            this.dialog = false
            this.e1 = 1
        }
    }
}
</script>

<style scoped>
.listActiveLi {
    background: #F4F7FC;
    border-radius: 14px;
}

. {
    border-radius: 14px;
}

.activeList {
    border-radius: 14px !important;
    color: black;
}

.lgNavigation {
    margin-top: 1px !important;
}


.avatar {
    margin-top: 40px;
}


.navigationList {
    margin-top: 5px !important;
}

.logoDiv {
    margin-top: 25px;
}

.NavigationIcon {
    border-right: 3px solid #507BE8;
}

.welcome {
    margin-top: 10px;
}

.appBar {
    margin-right: 20px;
    background: #FCFDFF !important;
    height: 90px;
}

.searchInput {
    margin-top: 25px;
    margin-left: 20px;
    border: #EDF0F5 !important;

}

.v-text-field--outlined fieldset {
    border: #EDF0F5 !important;
}

.magnatInfo {
    margin-top: 30px;
    margin-left: 20px;
}
.imageTitle{
    background-color: #F2F5FA;
    padding: 10px;
    border-radius: 14px;
}
.v-card > .v-card__progress + :not(.v-btn):not(.v-chip):not(.v-avatar), .v-card > :first-child:not(.v-btn):not(.v-chip):not(.v-avatar) {
    display: none;
}

@media (min-width: 1850px) {
    .NavigationIcon {
        border-right: 3px solid #507BE8;
    }

    .imageTitle {
        margin-top: 30px !important;
    }

    .appBar {
        margin-right: 80px;
    }


    .welcome {
        margin-top: 30px;
    }

    .toolsNav {
        margin-top: 20px;
        margin-right: 20px;
    }

    .searchInput {
        margin-top: 50px;
    }

    .lgNavigation {
        margin-top: 30px !important;
    }

    .avatar {
        margin-top: 120px !important;
    }

    .navigationList {
        margin-top: 50px !important;
    }

    .logoDiv {
        margin-top: 50px;
    }
}

@media (min-width: 1600px) {
    .NavigationIcon {
        border-right: 3px solid #507BE8;
    }

    .welcome {
        margin-top: 40px;
    }


    .toolsNav {
        margin-top: 20px;
        margin-right: 20px;
    }

    .magnatInfo {
        margin-top: 60px;
        margin-left: 50px;
        font-size: 20px;
    }

    .searchInput {
        margin-top: 50px;
    }

    .lgNavigation {
        margin-top: 15px !important;
    }

    .avatar {
        margin-top: 80px;
    }
}
</style>
